import { createRouter, createWebHistory } from 'vue-router';
import store from '@/store'
import {waitForStorageToBeReady} from '../utils'
import LandingPage from '../views/LandingPage'
import DrawingPage from '../views/DrawingPage'
import MessageSelectorPage from '../views/MessageSelectorPage'
import ContactPage from '../views/ContactPage'
import PreviewPage from '../views/PreviewPage'
import SharingPage from '../views/SharingPage'
import GalleryPage from '../views/GalleryPage'
import StaticGalleryPage from '../views/StaticGalleryPage'
import DoGoodPage from '../views/DoGoodPage'
import ThanksPage from '../views/ThanksPage'
import VideoBgSelectorPage from '../views/VideoBgSelectorPage'
import VideoAnimationSelectorPage from '../views/VideoAnimationSelectorPage'
import { trackRouter } from "vue-gtag-next"

var hash = location.hash

if (hash && hash.indexOf('#!') === 0) {
  location.hash = hash.slice(2)
}

export const router = createRouter({
    history: createWebHistory(),
    routes: [
      {
        path: '/',
        name: 'Landing',
        component: LandingPage,
      },
      {
        path: '/drawing',
        name: 'Drawing',
        component: DrawingPage,
        meta: { requiresAuth: true }
      },
      {
        path: '/:birdUuid/message',
        name: 'Message',
        component: MessageSelectorPage,
        meta: { requiresAuth: true }
      },      
      {
        path: '/:birdUuid/contact',
        name: 'Contact',
        component: ContactPage,
        meta: { requiresAuth: true }
      },      
      {
        path: '/:birdUuid/preview',
        name: 'Preview',
        component: PreviewPage,
      },
      {
        path: '/share/:birdUuid/',
        name: 'Sharing',
        component: SharingPage,
      },    
      {
        path: '/:birdUuid/:videoUuid/video/bg',
        name: 'Video Background',
        component: VideoBgSelectorPage,
        meta: { requiresAuth: true }
      },
      {
        path: '/:birdUuid/:videoUuid/video/animation',
        name: 'Video Animation',
        component: VideoAnimationSelectorPage,
        meta: { requiresAuth: true }
      },    
      {
        path: '/gallery',
        name: 'Gallery',
        component: GalleryPage,
      },
      {
        path: '/staticgallery',
        name: 'StaticGallery',
        component: StaticGalleryPage,
      },
      {
        path: '/dogood',
        name: 'DoGood',
        component: DoGoodPage,
      },   
      {
        path: '/:birdUuid/thanks',
        name: 'Thanks',
        component: ThanksPage,
      },   
    ],
    scrollBehavior (to, from, savedPosition) {
      // if (savedPosition) {
      //   return savedPosition;
      // }
  
      // if (to.hash) {
      //   return { selector: to.hash };
      // }
      // return { x: 0, y: 0 }
      document.getElementById('app').scrollIntoView();
    }
  });

  router.beforeEach(async (to, from, next) => {
    await waitForStorageToBeReady()
    if(to.query['whatsappshare']) {
      // whatsapp share hack
      next(to.query['whatsappshare'])
    }
    if(to.query.fbclid) {
      if(to.fullPath.split("#")[1]) {
        // Facebook shares will go here
        next(`${to.fullPath.split("#")[1]}`)
      } else {
        // Facebook ads link will go here
        next({path: '/'})
      }
      
    }
    if (to.matched.some(record => record.meta.requiresAuth)) {
      if (!store.getters.getToken) {
        next({
          path: '/'
        })
      } else {
        next()
      }
    } else {
      next()
    }
  });

  trackRouter(router);

  export default router